import React from "react"
// import { Link, useStaticQuery, graphql } from "gatsby"

// import Layout from "../components/layout"

const GalleryPage = () => {
  // const bgImg = useStaticQuery(graphql`
  //   query {
  //     image1: file(relativePath: { eq: "gallery/chair_overview_green.jpeg" }) {
  //       childImageSharp {
  //         fluid(maxWidth: 4032) {
  //           ...GatsbyImageSharpFluid
  //         }
  //       }
  //     }
  //     image2: file(relativePath: { eq: "gallery/counter.jpeg" }) {
  //       childImageSharp {
  //         fluid(maxWidth: 4032) {
  //           ...GatsbyImageSharpFluid
  //         }
  //       }
  //     }
  //   }
  // `)

  return (
    <></>
  )
}

export default GalleryPage;